.root {
  width: 100%;
  .row {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 100%;
    .box {
      background-color: #fff;
      width: 100%;
      padding: 20px;
      border-radius: 4px;
    }
    .btnGroup{
      display: flex;
      justify-content:flex-end;
      margin-top: 20px;
      .btn{
        color: #fff;
        background-color: #086df4;
        border-radius: 8px;
        padding: 12px 28px;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-transform: none;
      }
    }
  }
 
}
