.calendar-wrapper {
  background-color: #fff;
  // padding: 20px;

  .calendar {
    width: 100%;
    position: relative;
    height: 650px;
    overflow-x: scroll;

    table {
      border-collapse: collapse;

      &,
      td,
      th {
        border: 1px solid #e5e5e5;
      }
      .time-range-head {
        min-width: 126px;
        height: 50px;
      }
      .time-range {
        min-width: 124px;
        height: 40px;
      }
      th {
        min-width: 138px;
        height: 50px;
        padding: 0;
        &:nth-child(7),
        &:nth-child(8) {
          background: #b1b1b1;
        }

        .table-cell {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      td {
        max-width: 138px;
        min-width: 138px;
        height: 42px;
        padding: 0;

        .table-cell {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .checked {
          display: flex;
          // justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          padding: 4px 12px;
          background: rgba(0, 103, 244, 0.1);

          .text {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.006em;
            text-align: left;
            margin: 0;
            color: rgba(0, 103, 244, 1);
          }
        }
      }
    }
    // tr:first-child > td {
    //   border-top: none;
    // }
    // tr > td:last-child {
    //   border-right: none;
    // }
  }
}
