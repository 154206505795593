.root {
  .header {
    padding: 16px 20px;
    border-radius: 8px 8px 0 0;
    background-color: #fff;
    .title {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      letter-spacing: 0px;
    }
  }
  .row {
    display: flex;
    // margin: 20px;
    margin: 80px 20px 20px 20px;
    max-width: 100%;
    border-radius: 0 0 8px 8px;
    background-color: #fff;
    overflow: hidden;
    .step {
      flex: 0 0 30%;
      max-width: 30%;
    }
  }
  .MuiStepper-root {
    padding: 20px !important;
  }
}
.respondents_tabBlock {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  .respondents_lable {
  }
  .respondents__index {
    display: flex;
    align-items: center;
    justify-content: center;
    background: tomato;
    color: #fff;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    font-size: 10px;
  }
}
