.root {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  .row {
    display: flex;
    justify-content: space-between;
    padding: 11px 32px;
    background-color: #fff;
    .header {
      display: flex;
      align-items: center;
      .title {
        color: #303940;
        letter-spacing: -0.019em;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        font-family: "Inter";
      }
    }
    .btnGroup {
      .btn {
        padding: 8px 12px;
        background: #0067f4;
        border-radius: 8px;
        svg {
          margin-right: 12px;
        }
        span {
          font-family: "Inter";
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.006em;
          color: #fff;
          text-transform: none;
        }
      }
      .successBtn {
        padding: 8px 12px;
        background: #00b427;
        border-radius: 8px;
        margin-right: 15px;
        span {
          font-family: "Inter";
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.006em;
          color: #fff;
          text-transform: none;
        }
      }
    }
  }
}
