
@font-face {
  font-family: Inter;
  font-weight: 800;
  src: url("./Inter-ExtraBold.ttf");
}

@font-face {
  font-family: Inter;
  font-weight: 700;
  src: url("./Inter-Bold.ttf");
}

@font-face {
  font-family: Inter;
  font-weight: 600;
  src: url("./Inter-SemiBold.ttf");
}

@font-face {
  font-family: Inter;
  font-weight: 500;
  src: url("./Inter-Medium.ttf");
}

@font-face {
  font-family: Inter;
  font-weight: 400;
  src: url("./Inter-Regular.ttf");
}
