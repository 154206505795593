.date-picker {
  .date-input {
    width: 280px;
    font-size: 14px;
    line-height: 24px;
    border: 1px solid #dde2e4;
    padding: 4px 8px;
    border-radius: 6px;
    display: flex;
    align-items: center;

    &.focus {
      border-color: #60a5fa;
    }

    &__icons {
      color: #b0babf;
      display: flex;
      align-items: center;

      &__icon {
        font-size: 18px;
        cursor: pointer;

        &:hover {
          color: #787d80;
        }
      }
    }
  }

  .date-picker__calendar {
    position: absolute;

    .rc-calendar-input-wrap {
      display: none;
    }
  }
}

.rc-calendar-footer {
  display: none !important;
}
