@import url("./assets/fonts/fonts.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-body;
  }
}
input {
  outline: 0;
  background-color: transparent;
  width: 100%;
  transition: all 0.3s;
}
#app_bar .MuiTabs-indicator {
  background-color: #4094f7 !important;
}
#toast .Toastify .toast-container button {
  color: #fff;
  opacity: 1;
}
#toast .Toastify svg {
  fill: #fff !important;
}
#toast .Toastify__close-button {
  color: #fff !important;
  opacity: 1 !important;
}
#tableBody .tableStatus {
  padding: 4px 8px 4px 12px !important;
  border-radius: 6px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  max-width: 154px;
  width: 100%;
}
#tableBody .tableRow {
  font-size: 14px;
  font-family: "Inter";
  letter-spacing: -0.006em;
  line-height: 24px;
  color: #303940;
}
#text_limit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 21px;
  max-height: 25px;
  max-width: 300px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
#stepper .MuiPaper-root {
  display: flex;
  flex-direction: column;
}
#stepper .MuiStep-root {
  border: none;
  margin-bottom: 80px;
}
#stepper .MuiStepConnector-root {
  transform: rotate(90deg);
  top: -40px;
  position: relative;
  left: 135px;
}
#inputmask {
  padding: 9.5px 12px;
  margin-top: 12px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #303940;
  border: 1px solid #e5e9eb;
  border-radius: 6px;
}
#tab .MuiTabs-indicator {
  background-color: transparent !important;
}
#show_rating .MuiDialog-paperWidthSm {
  /* width: 100%; */
  min-width: 250px;
}
#balance .MuiDialog-paperWidthSm {
  max-width: 496px;
  width: 100%;
}
#balance .header {
  padding: 16px 20px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #ebedee;
  display: flex;
  flex-direction: column;
}
#balance .header .title {
  color: #1a2024;
  font-family: "Inter";
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}
#balance .header .error {
  margin-top: 12px;
  display: flex;
}
#balance .header .error span {
  margin-left: 8px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  font-family: "Inter";
  letter-spacing: -0.006em;
  color: #f76659;
}
#balance .body {
  padding: 20px;
  display: flex;
  flex-direction: column;
}
#balance .first {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
#balance .first label {
  color: #48535b;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  font-family: "Inter";
}
#balance .first input {
  padding: 8px 12px;
  background: #f6f8f9;
  border: 1px solid #dde2e4;
  border-radius: 6px;
  max-width: 230px;
}
#balance .first .secondInput {
  padding: 8px 12px;
  background: #f6f8f9;
  border: 1px solid #dde2e4;
  border-radius: 6px;
  max-width: 230px;
}
#balance .third {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#balance .third label {
  font-weight: 600;
  font-family: "Inter";
  font-size: 18px;
  line-height: 24px;
  color: #0067f4;
}
#balance .third input {
  padding: 8px 12px;
  background: #f6f8f9;
  border: 1px solid #dde2e4;
  border-radius: 6px;
  max-width: 230px;
}
#balance .btnGroup {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
#balance .btnGroup .btn {
  margin-right: 16px;
  border: 1px solid #e5e9eb;
  border-radius: 6px;
  background-color: #fff;
  color: #0067f4;
  font-family: "Inter";
  letter-spacing: -0.006em;
  padding: 8px 45.5px;
  text-transform: none;
}
#balance .btnGroup .btnSecond {
  padding: 8px 31.5px;
  background: #0067f4;
  border-radius: 6px;
  color: #fff;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  text-transform: none;
}
#respondent_count:last-child {
  color: #6e8bb7;
}
#rating .MuiBox-root {
  margin: 0px !important;
}
#second_rating span {
  font-size: 1.3rem;
  text-align: center;
}
#tab_status .MuiTabs-indicator {
  display: none;
}
#download_file {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e9eb;
  padding: 0 12px;
  border-radius: 8px;
  cursor: pointer;
  margin-left: 8px;
}
#download_file:hover {
  border: 1px solid rgb(64, 148, 247);
}
#agreement img {
  display: flex;
  justify-content: center;
  width: 100% !important;
  height: 400px !important;
  object-fit: contain;
}
#agreement span {
  line-height: 28px !important;
}
#agreement a {
  color: #0e73f6;
  text-decoration: underline;
}
#agreement a span{
  color: #0e73f6 !important;
}