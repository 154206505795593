.fourth-step {
  .calendar {
    width: 100%;
    position: relative;
    height: 650px;
    overflow-x: scroll;

    table {
      border-collapse: collapse;
      &,
      td,
      th {
        border: 1px solid #e5e5e5;
      }
      .time-range-head {
        min-width: 126px;
        height: 50px;
      }
      .time-range {
        min-width: 124px;
        height: 40px;
      }
      th {
        min-width: 114px;
        height: 50px;
        padding: 0;
        &:nth-child(7),
        &:nth-child(8) {
          background: #b1b1b1;
        }
        .table-cell {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      td {
        min-width: 114px;
        height: 42px;
        padding: 0;

        .table-cell {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .checked {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          background: rgba(0, 103, 244, 0.1);
        }
      }
    }
    // tr:first-child > td {
    //   border-top: none;
    // }
    // tr > td:last-child {
    //   border-right: none;
    // }
  }
}

.root {
  padding: 20px;
  .row {
    .box {
      display: flex;
      flex-direction: column;
      position: relative;
      .tab {
        position: absolute;
        right: 20px;
        background-color: #fff;
        max-width: 392px;
        box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32),
          0px 4px 10px rgba(91, 104, 113, 0.1);
        border-radius: 8px;
        .tabBox {
          display: flex;
          .tabList {
            font-weight: 600;
            font-size: 17px;
            line-height: 22px;
            letter-spacing: -0.41px;
            color: #2c2c2c;
            flex: 0 0 50%;
          }
          .active {
            color: #fff;
            background-color: #007aff;
            border-radius: 8px;
          }
        }
      }
      .container {
        margin-top: 70px;
        .tabPanel {
          .panel {
            padding: 24px;
            background: #ffffff;
            box-shadow: 0px 8px 16px rgba(110, 139, 183, 0.12);
            border-radius: 8px 8px 0px 0px;
            .header {
              display: flex;
              justify-content: space-between;
              .title {
                font-weight: 700;
                font-size: 24px;
                line-height: 40px;
                color: #000;
                font-family: "Inter";
              }
              .price {
                color: #1ac19d;
                font-size: 24px;
                line-height: 40px;
                font-weight: 700;
                font-family: "Inter";
              }
            }
            .time {
              display: flex;
              justify-content: space-between;
              margin-top: 12px;
              .left {
                display: flex;

                .timeList {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 24px;
                  color: #000;
                  font-family: "Inter";
                  padding: 8px 16px;
                  background: linear-gradient(
                      0deg,
                      rgba(110, 139, 183, 0.12),
                      rgba(110, 139, 183, 0.12)
                    ),
                    #ffffff;
                  border-radius: 28px;
                }
              }
              .right {
                display: flex;
                .itemList {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  background: rgba(0, 103, 244, 0.1);
                  border-radius: 8px;
                  padding: 9px 15px;
                  font-weight: 600;
                  font-family: "Inter";
                  font-size: 16px;
                  line-height: 24px;
                  color: #0067f4;
                  svg {
                    margin-right: 15px;
                  }
                }
              }
            }
            .hr {
              background: #e5e9eb;
              border-radius: 1px;
              margin: 32px 0 24px 0;
              height: 1px;
            }
            .description {
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #222222;
              font-family: "Inter";
            }
            .respondent {
              max-width: 327px;
              .title {
                font-weight: 700;
                font-family: "Inter";
                font-size: 16px;
                line-height: 24px;
              }
              .details {
                margin-top: 24px;
                display: flex;
                justify-content: space-between;
                .left {
                  display: flex;
                  flex-direction: column;
                  .name {
                    color: #000;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                    font-family: "Inter";
                  }
                }
                .right {
                  .answer {
                    font-family: "Inter";
                    font-size: 14px;
                    line-height: 21px;
                    color: #303940;
                  }
                }
              }
            }
            .footer {
              margin-top: 24px;
              display: flex;
              .connect {
                margin-right: 40px;
                display: flex;
                .icon {
                  width: 48px;
                  height: 48px;
                  background: rgba(0, 103, 244, 0.1);
                  border-radius: 8px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 16px;
                }
                .content {
                  display: flex;
                  flex-direction: column;
                  .typeConnect {
                    font-family: "Inter";
                    font-weight: 600;
                    color: #6e8bb7;
                    font-size: 14px;
                    line-height: 16px;
                    margin-bottom: 8px;
                  }
                }
              }
            }
          }
          .btnGroup {
            margin-top: 40px;
            display: flex;
            float: right;
            .btn {
              color: #0067f4;
              letter-spacing: -0.006em;
              text-align: center;
              padding: 8px 16px;
              border: 1px solid #0067f4;
              margin-right: 16px;
              font-weight: 500;
              border-radius: 6px;
              background-color: #fff;
              font-family: "Inter";
              text-transform: none;
            }
            .btnSecond {
              padding: 8px 31.5px;
              background: #0067f4;
              border-radius: 6px;
              color: #fff;
              font-family: "Inter";
              font-weight: 500;
              text-transform: none;
            }
            .btnThird {
              padding: 9px 31.5px;
              background: #0067f4;
              border-radius: 6px;
              color: #fff;
              font-family: "Inter";
              font-weight: 500;
              text-transform: none;
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
}
