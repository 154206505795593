.panel {
  background-color: #fff;
  max-height: 65px;
  height: 100%;
  padding: 14px;
  display: flex;
  justify-content: space-between;
  .rowPanel {
    display: flex;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 14px;
      cursor: pointer;
    }
    .path {
      display: flex;
      gap: 8px;
      margin-right: 26px;
      cursor: pointer;
      .icon {
        display: flex;
        align-items: center;
        svg {
          fill: #d5dadd;
        }
      }
      .title {
        font-weight: 400;
        font-family: 'Inter';
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.006em;
        color: #6e7c87;
      }
    }
    .active {
      .icon {
        svg {
          fill: #4094f7;
        }
      }
      .title {
        color: #252c32;
      }
    }
  }
  .btnGroup {
    display: flex;
    .btn {
      background: #0067f4;
      border-radius: 8px;
      padding: 8px 12px;
      display: flex;
      align-items: center;
      cursor: pointer;
      text-transform: capitalize;
      svg {
        margin-right: 12px;
      }
      span {
        font-weight: 500;
        font-family: 'Inter';
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.006em;
        color: #fff;
      }
    }
  }
}

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  .top {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
  .header {
    margin-top: 53px;
    .appbar {
      box-shadow: inset 0px -1px 0px #e5e9eb;
      background-color: #fff;
      color: #6e8bb7;
    }
    .row {
      background-color: #fff;
      max-width: 640px;
      width: 100%;
      border-radius: 8px;
      .card {
        .cardTitle {
          padding: 16px 20px;
          box-shadow: inset 0px -1px 0px #ebedee;
        }
        .cardBody {
          padding: 16px 20px;
          input {
            max-width: 424px;
            width: 100%;
            padding: 8px 12px;
            font-weight: 400;
            font-family: 'Inter';
            font-size: 14px;
            line-height: 24px;
            color: #303940;
            letter-spacing: -0.006em;
            border: 1px solid #e5e9eb;
            box-sizing: border-box;
            border-radius: 6px;
          }
        }
      }
    }
  }
}
